import React from 'react'
import { PageProps } from 'gatsby'
import ServiceBanner from '../../components/ServiceBanner'
import ServiceContent from '../../components/ServiceContent'
import Button from '../../components/Button'
// import VideoPlayer from '../../components/VideoPlayer'
import PretSmartphoneContainer from '../../components/PretSmartphoneContainer'
import { magasinLink } from '../../data/menu'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'

const title = 'Prêt si immobilisation'

const PretSmartphonePage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Si nous devons immobiliser votre téléphone, nous vous en prêtons un le temps de la réparation ! Pas de risque d'être déconnecté déconnecté avec Save !"
        />
      </Helmet>

      <PretSmartphoneContainer>
        <ServiceBanner
          title={title}
          background="/images/services/4_pret_si_immo/pret-si-immo-banner.jpg"
          parallax
        />

        <ServiceContent>
          <ServiceContent.Paragraph>
            Si nos experts ne peuvent pas réparer votre smartphone immédiatement
            mais que vous ne pouvez plus l'utiliser car il est trop endommagé,
            nous ne vous laissons pas tomber !
          </ServiceContent.Paragraph>
          <Button.ExternalLink href={magasinLink}>
            Trouver mon magasin
          </Button.ExternalLink>
          <ServiceContent.Title>Ne vous déconnectez pas</ServiceContent.Title>
          <ServiceContent.Paragraph>
            Si pour une raison particulière votre téléphone ne peut pas être
            réparé tout de suite, certains de nos magasins peuvent vous proposer
            un appareil de prêt (sous réserve de stock disponible). Si vous le
            souhaitez, nos experts peuvent même transférer tous vos contacts sur
            l'appareil de prêt, afin de faciliter la séparation avec votre
            précieux compagnon.
          </ServiceContent.Paragraph>
          <ServiceContent.Title>
            Intégration de votre carnet d’adresses
          </ServiceContent.Title>
          <ServiceContent.Paragraph>
            Pour pouvoir communiquer facilement avec vos proches, nous pouvons
            vous livrer l’appareil de prêt avec l’intégralité de vos contacts
            (9€).
          </ServiceContent.Paragraph>
          <ServiceContent.Title>
            Suppression des données après utilisation
          </ServiceContent.Title>
          <ServiceContent.Paragraph>
            Grâce à notre outil professionnel, vos données personnelles seront
            supprimées du téléphone de prêt en moins d'une minute et certifiée
            RGPD ! Aucune risque que l'utilisateur suivant se retrouve avec le
            numéro de votre maman !
          </ServiceContent.Paragraph>
        </ServiceContent>

        {/* <VideoPlayer
          src="/videos/Recover_Save.mp4"
          thumbnail="/videos/Recover_Save_thumbnail.jpg"
          ratio={{ width: 1920, height: 1080 }}
        /> */}
      </PretSmartphoneContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Nous vous prêtons un téléphone si ne le réparons pas le jour-même',
  breadcrumb: [servicesBreadcrumbElement, { label: title }],
  showHighlights: true,
}

export default Object.assign(PretSmartphonePage, {
  layoutProps,
})
